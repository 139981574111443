.home {
    min-height: calc(100vh - (var(--size-header)));
    max-width: 100vw;
    margin: 0 auto 0 auto;

    & .hero {
        display: none;
    }

    a:focus {
        color: var(--color-button-fg);
    };
}

.recent {
    @include containerLayout(
        grid,
        $rows: auto, 
        $columns: repeat(3, 1fr),
        $gap: var(--size-root-full), 
        $padding: var(--size-root-full),
        $width: var(--size-max),
        $margin: 0 auto
    );

    & h2 {
        grid-column: 1 / -1;
    }

    & article {
        grid-column: 1 / -1;
    }
}

.carousel {

    .control-arrow {

        @include breakpoint(small) {
            display: none;
        }
    }

    .slide {
        transition: all;

        &--message {
            opacity: 0;
            grid-gap: 2vw;
            justify-content: start;
            justify-items: start;
            margin: 0 10vw ;
            color: var(--color-hero-content);

            @include breakpoint(small) {
                grid-gap: 5vw;
                justify-content: center;
                justify-items: center;
                margin: 0 auto;
            }

            & .small {
                font-size: 2.5vw;
                text-shadow: 0px 1px 2px var(--color-body);

                @include breakpoint(small) {
                    font-size: 5vw;
                }
            }

            & .large {
                font-size: 5vw;
                text-shadow: 0px 1px 2px var(--color-body);

                @include breakpoint(small) {
                    font-size: 8vw;
                }
            }
        }

        img {
            object-fit: cover;
            filter: blur(2px) opacity(.5);

            @include breakpoint(small) {
                object-fit: cover;
            }
        }

        svg {
            opacity: 0;
            pointer-events: none;
        }

        // animated elements
        &.selected {
            animation: fadeIn var(--duration-slow);
            
            & > div {
        
            .slide--message {
                opacity: 1;
                animation: fadeUp var(--duration-slow);
            }
            
            svg {
                opacity: 1;
                animation: fadeIn var(--duration-slow);
            }
        }
    }

        &.previous div {
            
            .slide--message {
                animation: fadeOut var(--duration-quick);
            }
    
            svg {
                animation: fadeOut var(--duration-quick);
            }
        }
    }    
}