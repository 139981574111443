.playlist {
    max-width: var(--size-player-width-max);
    max-height: var(--size-player-height-max);
    padding: 0;
    background-color: var(--color-background-player-list);
    backdrop-filter: var(--backdrop-filter);
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    transform: translate(0, 0);
    filter: blur(1px) opacity(0.5);
    transition: all var(--duration-quick);
    -webkit-font-smoothing: antialiased; // fix blurry text in chrome

    &__on {
        height: -webkit-fill-available;
        transform: translate(calc(var(--size-player-width) * -1), 0);
        filter: blur(0) opacity(1);
    }

    @include breakpoint(small) {

        &__on {
            transform: translate(calc(var(--size-player-width-small) * -1), 0);
        }
    }

    &__header {
        position: sticky;
        @include containerLayout(
            grid,
            $rows: auto, 
            $columns: minmax(25%, 1fr) minmax(var(--size-icon-medium), auto),
            $gap: initial,
            $padding: var(--size-root-half),
            $width: initial,
            $margin: initial
        );

        align-items: center;
        height: 60px;
        min-height: var(--size-touch);
        top: 0;
        color: var(--color-heading);
        background-color: var(--color-body);
        box-shadow: 0px 10px 20px -20px rgba(0, 0, 0, .3);

        & small {
            grid-row: 2;
        }
        
        &:last-child {
            text-align: end;
        }
    }

    &__data {
        grid-row: span 2;
        position: relative;
        display: flex;
        flex-direction: row-reverse;

        i {
            position: absolute;
            align-self: center;
            right: 0;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
        }
    
        i:before {
            content: '\f0b0';
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            color: var(--color-link);
            font-family: 'Font Awesome 5 Free';
            font-size: var(--size-root-full);
        }
        
        select {
            z-index: 1;
            appearance: none;
            direction: rtl; // required for right alignment on safari
            display: flex;
            width: 100%;
            height: var(--size-touch);
            margin: 0;
            padding: 0 var(--size-icon-large) 0 0;
            color: var(--color-link);
            border: none;
            background-color: transparent;
            font-size: var(--size-root-full);
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    li {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: start;
        gap: var(--size-root-half);
        padding: var(--size-root-half) var(--size-root-full);
        color: var(--color-heading);
        transition: all var(--duration-quick);
        cursor: pointer;

        p {
            line-height: initial;
        }
        
        small {
            display: flex;
            width: 100%;
            margin: var(--size-root-quarter) 0;
            color: var(--color-heading-sub);
            font-size: var(--size-font-small);
        }

        span {
            margin: 0 0 0 auto;
        }
    
        &:nth-child(even) {
            background-color: var(--color-background-player-list);
        }

        &.playing {
            border-left: var(--size-root-full) solid var(--color-link-h);
        }
    }
    
    &__mood {
        grid-row: span 2;
        display: block;
        width: var(--size-icon-medium);
        height: var(--size-icon-medium);
        margin: 0 var(--size-root-half);
        border-radius: var(--border-radius-full);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-track);
        border-radius: var(--border-radius);
    }

    &::-webkit-scrollbar {
        width: var(--size-width-track);
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-thumb);
        border-radius: var(--border-radius);
    }
}