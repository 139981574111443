@font-face {
    font-family: Roboto;
    src: url(#{$pathToFonts}Roboto/Roboto-Regular.ttf);
    font-display: swap;
}

body {
    // forces ios to use all the viewable area
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // lock body when nav is open
    &.locked {
        overflow: hidden;
    }
}

main {
    background: var(--color-body);
    min-height: 100vh;
}

.content {
    min-height: calc(100vh - var(--size-header));
    margin: 0 auto 0 auto;

    .reveal-animation {
        animation: fadeIn var(--duration);

        & .divider {
            animation: fadeIn var(--duration-slow) forwards;
        }
    }

    & > header,
    & > article > header,
    & > .projects header {
        @include containerLayout(
            grid,
            $rows: auto, 
            $columns: initial,
            $gap: var(--size-root-half) 0, 
            $padding: 10vw,
            $width: var(--size-max),
            $margin: 0 auto
        );

        position: relative;
        align-items: center;
        justify-items: center;
        align-content: center;
        min-height: calc(100vh - var(--size-header));
        background: var(--color-hero-page-background);
        white-space: pre-wrap;
        overflow: hidden;

        @include breakpoint(small) {
            padding: var(--size-root-full);
        }

        .heading {
            &:focus-visible {
                color: var(--color-body);
                text-shadow: 0px 2px 10px var(--color-link);
            }
        }

        .heading,
        .subheading {
            max-width: var(--size-detail);
            flex: 1 0 100%;
            text-align: center;
        }

        .subheading {
            max-width: var(--size-detail);
            color: var(--color-heading-sub);
            font-size: calc(var(--size-font) * 1.25);
        }

        .overview {
            max-width: var(--size-detail);
            font-size: var(--size-hero-page-font);
            animation: fadeUp var(--duration);

            @include breakpoint(small) {
                font-size: initial;
            }
        }

        & > svg {
            top: 0;
            left: 0;
            opacity: 0.1;
        }

        &:not(svg) {
            // move content on top of the svg
            z-index: 1;
        }
    }
}

.divider {
    width: 10vw;
    margin: var(--size-root-full) 0;
    border: none;
    border-bottom: 2px solid var(--size-hero-page-divider);
    animation: fadeIn var(--duration-slow);

    @include breakpoint(small) {
        width: 20vw;
    }
}

.spacer {
    margin: 0 0 var(--size-root-2x) 0;
}

.featured {
    grid-column: span 2;
    grid-row: span 2;
}

.projects__detail,
.about__detail .details {
    & > h2,
    & > h3 {
        position: sticky;
        top: var(--size-header);
        background-color: var(--color-body);
        backdrop-filter: var(--backdrop-filter);
    }
}

footer {
    @include containerLayout(
        grid,
        $rows: 1fr, 
        $columns: repeat(2, 1fr),
        $gap: var(--layout-content-gap), 
        $padding: var(--size-root-full),
        $width: var(--size-max),
        $margin: initial
    );
    
    min-height: var(--size-footer);
    align-self: stretch;
    color: var(--color-footer-fg);
    background: var(--color-footer);

    @include breakpoint(small) {
        grid-template: 1fr / 1fr;
    }
}