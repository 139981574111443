.uiux {
    @include containerLayout(
        grid,
        $rows: auto, 
        $columns: repeat(3, minmax(200px, 1fr)),
        $gap: var(--size-root-full), 
        $padding: var(--size-root-full),
        $width: var(--size-detail),
        $margin: 0 auto
    );
    white-space: break-spaces;

    @include breakpoint(small) {
        grid-template: auto / repeat(2, 1fr);
    }

    h1,
    h2,
    h3,
    p {
        width: 100%;
        grid-column: span 3;

        @include breakpoint(small) {
            grid-column: span 2;
        }
    }

    h2 {
        z-index: var(--depth-headings);
        position: sticky;
        top: var(--size-header);
        margin-top: calc(4 * #{var(--size-root-full)});
        background-color: var(--color-body);
    }

    h3 {
        @extend h5;
        margin-top: var(--size-root-2x);
    }

    hr {
        @extend .divider;

        @include breakpoint(small) {
            grid-column: span 2;
        }
    }

    &__media {
        z-index: var(--depth-content);
        border-radius: calc(var(--border-radius) * 1.5);
        border: 4px solid transparent;
        cursor: pointer;
        // border-radius: var(--border-radius);
        // background-color: var(--color-body-secondary);
        // background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
        // overflow: hidden;

        &:hover,
        &:focus {
            border-color: var(--color-link-h);
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);
        }

        figcaption {
            position: relative;
            display: block;
            color: var(--color-content);
            padding: var(--size-root-half);
            font-size: small;
            line-height: calc(var(--line-height) * 1.25);
            pointer-events: none; // allow click through
        }

        & > img {
            aspect-ratio: 16/9;
            width: 100%;
            // height: 100%;
            object-fit: cover;
            border-radius: var(--border-radius);
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            overflow: hidden;
            pointer-events: none; // allow click through
        }
    }
}
