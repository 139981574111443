.sound {
    max-width: var(--size-max);
    margin: var(--size-root-full) auto;
    animation: fadeUp var(--duration);
    
    
    @include breakpoint(small) {
        width: 100%;
        margin: 0 auto;
    }
    
    &__elements {
        @include containerLayout(
            grid,
            $rows: auto, 
            $columns: repeat(3, var(--size-player-width)),
            $gap: 0, 
            $padding: 0,
            $width: var(--size-player-width-max),
            $margin: 0 auto
        );
        height: var(--size-player-height);
        backdrop-filter: var(--backdrop-filter);
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        overflow-x: hidden;

        @include breakpoint(small) {
            grid-template: auto/repeat(3, var(--size-player-width-small));
            border-radius: 0 0 0 0;
        }
    }

    &--loading {
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        align-content: center;
        justify-content: center;
        height: 50vh;
        color: var(--color-loading);
        animation: loading var(--duration-quick) infinite;
        
        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: var(--size-icon-2xlarge);
            font-family: 'Font Awesome 5 Free';
            font-size: calc(var(--size-root-full) * 3);

            &::before {
                content: '\f001';
            }
        }
    }
}

// .color-backdrop {
//     // background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%) no-repeat;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     // z-index: -1;
    
//     &.playing {
//         animation: colorChange 20s alternate infinite;
//     }
// }