
.mode__dark {

    // main theme dark
    --color-header: rgba(20, 20, 20, 0.9);
    --color-body: rgba(25, 25, 25, 1);
    --color-body-secondary: rgba(30, 30, 30, 1);
    --color-footer: rgba(20, 20, 20, 1);
    --color-nav: rgba(20, 20, 20, 0.95);

    --color-heading: rgba(220, 220, 220, 1);
    --color-heading-sub: rgba(180, 180, 180, 1);
    --color-content: rgba(140, 140, 140, 1);
    --color-content-highlight: rgba(180, 180, 180, 1);
    --color-content-light: rgba(40, 40, 40, 1);
    --color-footer-fg: rgba(140, 140, 140, 1);

    --color-link: rgba(255, 0, 110, 1);
    --color-link-h: rgba(255, 0, 110, 0.7);

    --color-loading: rgba(180, 180, 180, 1);

    // scrollbars
    --color-track: rgba(20, 20, 20, 1);
    --color-thumb: rgba(255, 0, 110, 1);

    // custom content
    --color-hero-content: var(--color-heading);
    --color-hero-background: var(--color-body);
    
    --color-hero-page-background: radial-gradient(closest-side, rgba(40, 40, 40, 1), var(--color-body));
    --size-hero-page-divider: rgba(255, 0, 110, 1);

    --color-background-profile: rgba(0, 0, 0, 1);
    --color-meter: rgba(80, 80, 80, 1);
    --color-meter-label: rgba(245, 245, 245, 1);
    --color-meter-bar-fg: rgba(255, 0, 110, 1);
    --color-meter-bar-bg: rgba(255, 0, 110, 0.7);

    --color-media-overlay: linear-gradient(0deg, rgba(245, 245, 245, 1) 100%, transparent);

    --color-galery-background: rgb(255, 255, 255);
    --color-gallery-overlay: rgba(0, 0, 0, 0.75);

    // audio player
    --color-background-player: var(--color-body);
    --color-background-player-list: rgba(180, 180, 180, 0.1);
}
