.audio-player {
    @include containerLayout(
        grid,
        $rows: auto, 
        $columns: repeat(9, 1fr),
        $gap: var(--size-root-half), 
        $padding: var(--size-root-full) var(--size-root-full) var(--size-touch) var(--size-root-full),
        $width: 100%,
        $margin: 0 auto
    );
    
    align-self: flex-start;
    align-content: center;
    align-items: center;
    justify-items: center;
    max-width: var(--size-player-width-max);
    max-height: var(--size-player-height-max);
    transform: translate(0, 0);
    transition: all var(--duration-quick);

    &__off {
        transform: translate(calc(var(--size-player-width) * -1), 0);
        filter: blur(4px) opacity(0.5);
    }

    @include breakpoint(small) {
        height: calc(100vh - (var(--size-header)) - (var(--size-footer)));
        max-height: initial;
        padding: 0;

        &__off {
            transform: translate(calc(var(--size-player-width) * 2 * -1), 0);
        }
    }

    &__artwork {
        grid-column: 1/-1;
        grid-row: 1;
        display: flex;
        justify-content: center;
        min-height: var(--size-player-artwork);
        width: var(--size-player-artwork);
        margin-bottom: var(--size-root-2x);
        border-radius: var(--border-radius);
        background: var(--color-body);
        box-shadow: 0px 10px 20px 5px var(--mood-current);
        transition: all var(--duration-quick) ease-in-out;
        overflow: hidden;
        
        @include breakpoint(small) {
            min-height: var(--size-player-artwork-small);
            width: var(--size-player-artwork-small);
        }
        
        img {
            width: 100%;
            object-fit: scale-down;
        }

        svg {
            width: 100%;
        }
        
        // &--paused {
        //     border-radius: var(--border-radius);
        // }
    }

    &__title {
        grid-column: 3/-1;
        grid-row: 2;
        justify-self: flex-start;
        color: var(--color-heading);
        font-size: var(--size-font);

        @include breakpoint(small) {
            grid-column: 1/-1;
            padding: 0 var(--size-root-full);
        }
    }

    &__artist {
        grid-row: 3;
        grid-column: 3/-1;
        justify-self: flex-start;
        color: var(--color-heading-sub);
        font-size: var(--size-font-small);

        @include breakpoint(small) {
            grid-column: 1/-1;
            padding: 0 var(--size-root-full);
        }
    }

    &__time {
        &--current,
        &--remaining {
            grid-row: 4;
            flex: 1;
            justify-self: center;
        }

        &--current {
            grid-column: 1 / span 2;
            color: var(--color-heading-sub);
            font-size: var(--size-font-small);
        }

        &--remaining {
            grid-column: 8 / span 2;
            color: var(--color-heading-sub);
            font-size: var(--size-font-small);
        }
    }

    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        border-radius: 8px;
        transition: background 0.2s ease;
        cursor: pointer;

        border: var(--focus-border-hidden);

        &:focus-visible {
            color: var(--color-link-h);
            border: var(--focus-border-active);
        }

        &:hover,
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        &::-webkit-slider-thumb {
            display: block;
            -webkit-appearance: none;
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            border-radius: var(--border-radius-full);
            background: radial-gradient(circle, var(--color-range-thumb) 65%, rgba(255, 255, 255, 0) 75%);
            box-shadow: 0 0 0 transparent;

            @include breakpoint(small) {
                transform: scale(1);
                transition: scale var(--duration-quick) ease-in-out;
            }

            &:active {
                background: radial-gradient(circle, var(--color-range-thumb) 0%, var(--color-range-thumb) 100%);
                box-shadow: var(--box-shadow-shallow);

                @include breakpoint(small) {
                    transform: scale(2);
                }
            }
        }
    }

    &__progress {
        grid-column: 3 / span 5;
        grid-row: 4;
        margin: var(--size-root-full) 0;
    }

    &__volume {
        grid-column: 3 / span 5;
        grid-row: 6;
        margin: var(--size-root-full) 0;

        &--up,
        &--down {
            grid-row: 6;
            flex: 1;
            min-width: var(--size-icon-small);
            width: var(--size-icon-small);
            height: var(--size-icon-small);
            display: flex;
            align-self: center;
            justify-content: center;
            color: var(--color-link);
            font-family: "Font Awesome 5 Free";
            font-size: var(--size-root-full);
        }

        &--up {
            grid-column: 8 / span 2;

            &:before {
                content: "\f028";
            }
        }

        &--down {
            grid-column: 1 / span 2;

            &:before {
                content: "\f027";
            }
        }
    }

    &__seek--next,
    &__seek--prev,
    &__play,
    &__pause {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-link);
        font-size: 0;
        border: var(--focus-border-hidden);
        background: transparent;
        cursor: pointer;


        &:focus-visible {
            color: var(--color-link-h);
            border: var(--focus-border-active);
        }

        &:hover,
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Font Awesome 5 Free";
            font-size: var(--size-root-full);
        }
    }

    &__seek {
        &--next {
            grid-column: 6 / span 2;

            & i {
                min-width: var(--size-icon-large);
                width: var(--size-icon-large);
                height: var(--size-icon-large);

                &:before {
                    content: "\f04e";
                }
            }
        }

        &--prev {
            grid-column: 3 / span 2;

            & i {
                min-width: var(--size-icon-large);
                width: var(--size-icon-large);
                height: var(--size-icon-large);

                &:before {
                    content: "\f04a";
                }
            }
        }
    }

    &__play,
    &__pause {
        display: flex;
        flex: 1;

        & i {
            font-size: var(--size-root-2x);
            min-width: var(--size-icon-xlarge);
            width: var(--size-icon-xlarge);
            height: var(--size-icon-xlarge);
        }
    }

    &__play i:before {
        content: "\f04b";
    }

    &__pause i:before {
        content: "\f04c";
    }
}

// .audio-player__small {
//     --size-player: auto;
//     --size-player-artwork: 60px;

//     .audio-player__artwork {
//         grid-column: 1/span 2;
//         grid-row: 1/ span 2;
//         margin: 0;

//         img {
//             width: -webkit-fill-available;
//         }
//     }

//     .audio-player__title {
//         grid-column: 3/span 7;
//         grid-row: 1;
//     }
//     .audio-player__artist {
//         grid-column: 3/span 7;
//         grid-row: 2;
//     }

//     .audio-player__progress {
//         grid-row: 3;
//     }

//     .audio-player__play,
//     .audio-player__pause {
//         grid-column: 8/span 2;
//         grid-row: 1/span 2;
//     }

//     .audio-player__seek--prev,
//     .audio-player__seek--next {
//         display: none;
//     }

//     .audio-player__volume,
//     .audio-player__volume--down,
//     .audio-player__volume--up {
//         display: none;
//     }

//     .audio-player__time {
//         &--current {
//             grid-row: 3/3;
//         }
//         &--remaining {
//             grid-column: 8/span 2;
//             grid-row: 3/3;
//             flex: 1;
//             justify-self: initial;
//         }
//     }
// }
