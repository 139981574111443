// safari bug, won't display animation of filter
// @keyframes brandPulse {
//     from {
//         filter: drop-shadow(0px 1px .1rem var(--color-link));
//     }
//     to {
//         filter: drop-shadow(0px 1px .15rem var(--color-link));
//     }
// }

// @keyframes skullPulse {
//     from {
//         filter: drop-shadow(0px 4px 3px var(--color-link));
//     }
//     to {
//         filter: drop-shadow(0px 4px 6px var(--color-link));
//     }
// }

// svg backgrounds
.svg {

    &__brand--default {
        path:nth-of-type(1) { fill: #3f3f3f; }
        path:nth-of-type(2) { fill: #7f7f7f; }
        path:nth-of-type(3) { fill: #7f7f7f; }
        path:nth-of-type(4) { fill: #bfbfbf; }
    }

    &__brand {
        position: absolute;
        width: 100%;
        height: -webkit-fill-available;
        max-height: calc(100vh - (var(--size-header)));
        transform: translate3d(51vw, 0vh, 0px) scale(1.5);
        // animation: brandPulse var(--duration-slow) alternate-reverse infinite; // safari bug, won't display animation of filter
        filter: drop-shadow(0px 1px .75rem var(--color-link));
        
        @include breakpoint(small) {
            transform: translate3d(40vw, 0vh, 0px) scale(3);
        }
        
        path {
            fill: var(--color-body);
        }
    }

    &__skull {
        position: absolute;
        // display: flex;
        // align-self: center;
        // justify-self: center;
        width: 100%;
        height: -webkit-fill-available;
        // max-height: calc(100vh - (var(--size-header)));
        transform: translate3d(20vw, 0vh, 0px) scale(2);
        // animation: skullPulse var(--duration-slow) alternate-reverse infinite; // safari bug, won't display animation of filter
        filter: drop-shadow(0px 1px .75rem var(--color-link));

        @include breakpoint(small) {
            transform: translate3d(50vw, 0vh, 0px) scale(3);
        }
        
        path {
            fill: var(--color-body);
        }

        path:nth-child(2n) {
            fill: var(--color-body);
        }
    }
}