* {
    // forces ios to use all the height
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased !important;

    &:after,
    &:before {
        box-sizing: border-box;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    font-size: var(--size-font);
    line-height: var(--line-height);

    // safari will not "sticky" ui elements with below implemented
    // @include breakpoint(small) {
    //     overflow: auto;
    //     overflow-x: hidden;
    // }
}

:focus {
    outline: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(---font-family-heading);
    line-height: calc(var(--line-height) * 1.5);

    @include breakpoint(small) {
        line-height: calc(var(--line-height) * 1.25);
    }
}

h1 {
    color: var(--color-heading);
    font-size: calc(var(--size-font) * 3);
}

h2 {
    color: var(--color-heading);
    font-size: calc(var(--size-font) * 2.5);
}

h3 {
    color: var(--color-heading);
    font-size: calc(var(--size-font) * 2);
}

h4 {
    color: var(--color-heading-sub);
    font-size: calc(var(--size-font) * 1.5);
}

h5 {
    color: var(--color-heading-sub);
    font-size: calc(var(--size-font) * 1.25);
}

p {
    color: var(--color-content);
    font-family: var(--font-family);
    font-size: var(--size-font);
    line-height: calc(var(--line-height) * 2);
}

details {
    color: var(--color-content);
    font-family: var(--font-family);
    font-size: var(--size-font);

    &[open] {
        & summary > span:before {
            transform: rotate(90deg);
        }
    }

    summary {
        display: flex; // stops chrome showing the markers even though the display on them is set
        justify-content: space-between;
        justify-items: space-between;
        border: var(--focus-border-hidden);

        &:focus-visible {
            padding: 0 0 0 var(--size-root-half);
            color: var(--color-link-h);
            border: var(--focus-border-active);
        }

        &:hover,
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        & > span {
            flex: 1;
            display: flex; // anything aside from inline related styles causes desktop safari to bug
            flex-direction: row-reverse;
            align-items: center;
            min-height: var(--size-touch);
            // padding: var(--size-root-half) 0;
            color: var(--color-link);
            font-family: var(---font-family-heading);
            font-size: calc(var(--size-font) * 1.25);
            cursor: pointer;

            &:before {
                content: "\f0da";
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;
                width: var(--size-root-full);
                height: var(--size-touch);
                margin: 0 var(--size-root-half) 0 auto;
                font-family: var(--font-awesome);
                font-size: var(--size-root-full);
                transition: all var(--duration-quick);
            }
        }

        &::marker {
            display: none;
        }

        &::-webkit-details-marker {
            display: none;
        }
    }
}

b,
strong {
    font-weight: var(--font-weight-heavy);
}

em {
    font-style: italic;
}

a {
    color: var(--color-link);
    text-decoration: none;
    transition: all var(--duration-quick);
    border: var(--focus-border-hidden);
    cursor: pointer;

    &:focus-visible {
        color: var(--color-link-h);
        border: var(--focus-border-active);
    }

    &:hover,
    &:focus:not(:focus-visible) {
        color: var(--color-link-h);
    }
}

small {
    font-size: var(--size-font-small);
}

sub {
    font-size: var(--size-font-small);
    vertical-align: sub;
}

sup {
    font-size: var(--size-font-small);
    vertical-align: super;
}

cite {
    font-style: italic;
}

time {
    font-weight: var(--font-weight-heavy);
}

hr {
    display: flex;
    // flex: 1 0 100%;
    align-self: center;
    border: var(--size-hr) solid var(--color-hr);
    margin: 0;
}

ol {
    list-style: decimal;
    list-style-position: inside;
}

ul {
    list-style: none;
    list-style-position: inside;

    li > ul {
        padding: var(--size-root-half);
    }
}

address {
    color: var(--color-content);
    font-family: var(--font-family);
    font-size: var(--size-font);
    line-height: var(--line-height);
    white-space: pre;
}

blockquote {
    quotes: "\201C" "\201D" "\2018" "\2019";
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: var(--size-root-full);
    padding: var(--size-root-full);
    background: var(--color-blockquote-bg);
    border-left: var(--size-root-half) solid var(--color-border-all);

    &:before,
    &:after {
        position: absolute;
        color: var(--color-blockquote-icon);
        font-size: calc(var(--size-font) * 4);
        line-height: calc(var(--size-root-full) * 4);
        align-items: baseline;
    }

    &:before {
        content: open-quote;
        top: var(--size-root-full);
        left: var(--size-root-full);
    }

    // &:after {
    //     content: close-quote;
    //     right: var(--size-root-full);
    //     bottom: 0;
    // }

    & p {
        color: var(--color-blockquote-fg);
        flex: 0 0 95%;
        margin: 0 calc(var(--size-root-full) * 2) var(--size-root-half) calc(var(--size-root-full) * 2);
    }

    & cite {
        color: var(--color-blockquote-cite);
        flex: 0 0 95%;
        margin: 0 calc(var(--size-root-full) * 2) var(--size-root-half) calc(var(--size-root-full) * 2);
    }
}

table {
    display: grid;
    grid-template-areas: "thead" "tbody" "tfoot";
    flex: 1 1 100%;
    align-content: flex-start;

    thead {
        grid-area: thead;
        padding: var(--size-root-quarter);
        color: var(--color-table-head-fg);
        background: var(--color-table-head);
    }

    tfoot {
        grid-area: tfoot;
        padding: var(--size-root-quarter);
        color: var(--color-table-foot-fg);
        background: var(--color-table-foot);
    }

    tbody {
        grid-area: tbody;
        flex: 1 0 100%;
        color: var(--color-table-fg);
        // background: var(--color-table-bg);
    }

    tr {
        display: flex;
        justify-content: space-around;
        flex: 1 0 100%;

        &:nth-child(even) {
            border-left: none;
            border-right: none;
            // background: var(--color-table-row-alt);
        }
    }

    td {
        flex: 1;
        padding: var(--size-root-quarter);
    }
}

video {
    object-fit: cover;
    background-color: var(--color-body);
}

:root {

    ::-webkit-scrollbar {
        width: var(--size-width-track);
        background-color: var(--color-track);
    }

    ::-webkit-scrollbar-track {
        background-color: var(--color-track);
        border-radius: var(--border-radius);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--color-thumb);
        border-radius: var(--border-radius);
    }
}