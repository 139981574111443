.controls {
    
    &__view {
        position: sticky;

        @include containerLayout(
            grid,
            $rows: auto, 
            $columns: repeat(3, 1fr),
            $gap: var(--size-root-full), 
            $padding: var(--size-root-half),
            $width: 100%,
            $margin: 0 auto
        );
        
        align-items: center;
        justify-content: center;
        max-width: var(--size-player-width-max);
        top: 90vh;
        bottom: 0;
        background-color: var(--color-background-player);
        box-shadow: 0px -10px 20px -20px rgba(0, 0, 0, .3);
        border-radius: 0 0 var(--border-radius) var(--border-radius);

        @include breakpoint(small) {
            border-radius: 0 0 0 0;
        }
    }

    &__list,
    &__info {
        grid-column: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--size-touch);
        padding: 0;
        font-size: 0;
        color: var(--color-link);
        background: transparent;
        border: var(--focus-border-hidden);
        cursor: pointer;
        transition: all var(--duration-quick);
        
        &:hover {
            color: var(--color-link-h);
        }

        &:focus-visible {
            color: var(--color-link-h);
            border: var(--focus-border-active);
        }
        
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Font Awesome 5 Free';
            font-size: var(--size-root-full);
            min-width: var(--size-icon-large);
            width: var(--size-icon-large);
            height: var(--size-icon-large);
        }
    }

    &__list {
        grid-column: 3;

        &--list i:before {
            content: '\f001';
        }
        
        &--player i:before {
            content: '\f03a';
        }
    }
    
    &__info {
        grid-column: 2;
        
        &--on i:before {
            content: '\f00d';
        }
        
        &--off i:before {
            content: '\f129';
        }
    }
}