.mode__dark {
    .header--solid {
        box-shadow: none;
    }
}

.header {
    z-index: var(--depth-header);
    position: sticky;

    @include containerLayout(
        grid,
        $rows: var(--size-header) 1fr, 
        $columns: repeat(3, 1fr),
        $gap: 0 var(--size-root-full), 
        $padding: 0,
        $width: 100%,
        $margin: initial
    );
    
    align-items: center;
    height: var(--size-header);
    top: 0;
    background: var(--color-header);
    backdrop-filter: var(--backdrop-filter);
    transition: all var(--duration-quick);
    overflow-x: clip; // safari: is ignored, hides scroll display when nav is engaged

    & .brand {
        justify-self: center;
        width: fit-content;
        margin: 0;
        margin-top: 1rem;
        transform: scale(1);
        cursor: pointer;

        @include breakpoint(small) {
            border: var(--focus-border-hidden);
        }

        &:hover,
        &:focus {
            opacity: var(--alpha-3quarter);
        }

        &__svg {
            width: var(--size-brand);
            fill: var(--color-body);
            filter: drop-shadow(0px 2px 2px var(--color-link));
        }
    }

    &--solid {
        & .brand {
            margin-top: 0;
            transform: scale(0.75);

            &__svg {
                fill: var(--color-link);
                filter: drop-shadow(0px 0px 0px transparent);
            }
        }
    }

    & .toggle {
        -webkit-appearance: none;
        display: flex;
        gap: var(--size-root-half);
        align-content: stretch;
        align-items: center;
        width: fit-content;
        margin: var(--size-root-full);
        padding: 0;
        color: var(--color-link);
        font-size: var(--size-font-small);
        border: var(--focus-border-hidden);
        background: transparent;
        cursor: pointer;
        

        &:focus-visible {
            color: var(--color-link-h);
            border: var(--focus-border-active);
        }

        &:hover,
        &:focus:not(:focus-visible) {
            color: var(--color-link-h);
        }

        &:before {
            height: var(--size-icon-large);
            width: var(--size-icon-large);
            font-family: var(--font-awesome);
            font-size: var(--size-icon-large);
        }

        &__menu {
            justify-self: start;

            &:before {
                content: "\f0c9";
            }

            &--close:after {
                content: '';
                position: absolute;
                display: block;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                background: transparent;
                z-index: var(--depth-negative);
            }
        }

        &__settings {
            grid-column: 3;
            justify-self: end;

            &:before {
                // content: "\f013"; // gear
                content: "\f141"; //kebab
            }

            &--close:after {
                content: '';
                position: absolute;
                display: block;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                background: transparent;
                z-index: var(--depth-negative);
            }
        }
    }
}