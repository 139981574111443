.track-info {
    @include containerLayout(
        grid,
        $rows: auto, 
        $columns: repeat(3, 1fr),
        $gap: var(--size-root-half), 
        $padding: var(--size-root-full),
        $width: 100%,
        $margin: 0 auto
    );

    align-self: flex-start;
    align-items: center;
    justify-items: flex-start;
    max-width: var(--size-player-width-max);
    max-height: var(--size-player-height-max);
    background-color: var(--color-background-player);
    backdrop-filter: blur(10px); //var(--backdrop-filter);
    scroll-behavior: smooth;
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    transform: translate(0, 0);
    filter: blur(1px) opacity(0.5);
    transition: all var(--duration-quick);
    
    &__on {
        height: 100%;
        transform: translate(calc(var(--size-player-width) * -1 * 2), 0);
        filter: blur(0) opacity(1);
    }

    @include breakpoint(small) {

        &__on {
            transform: translate(calc(var(--size-player-width-small) * -1 * 2), 0)
        }
    }
    
    .audio-player__artwork {
        grid-row: auto;
        justify-self: center;

        svg {
            width: 100%;
        }
    }

    h2,
    h3,
    h4,
    h5 {
        grid-column: 1/-1;
        color: var(--color-heading);
    }

    p {
        grid-column: 1/-1;
    }
}