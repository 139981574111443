@import "_scss/variables";

// @import "_scss/root";
@import "_scss/root/root-colors-dark";
@import "_scss/root/root-colors-dark-base";
@import "_scss/root/root-colors-light";
@import "_scss/root/root-colors-light-base";
@import "_scss/root/root-layout";

//main files
@import "_scss/animation";
@import "_scss/reset";

//functions
@import "_scss/functions/ratio";
@import "_scss/functions/tint-shade";

// mixins
@import "_scss/mixins/responsive";
// @import "_scss/mixins/prefix";
// @import "_scss/mixins/icons-svg-ui";
// @import "_scss/mixins/svg-background";
@import "_scss/mixins/button";
@import "_scss/mixins/select";
@import "_scss/mixins/radio-check";
@import "_scss/mixins/ratio";
@import "_scss/mixins/container";

//fonts
@import "./fonts/fontawesome-free-5.15.2-web/scss/fontawesome";
@import "./fonts/fontawesome-free-5.15.2-web/scss/solid";
@import "./fonts/fontawesome-free-5.15.2-web/scss/brands";

//base
@import "_scss/base";
@import "_scss/app";
@import "_scss/buttons";
// @import "_scss/meter";
// @import "_scss/form";

//components
@import "_scss/components/loader";
@import "_scss/components/nav";
@import "_scss/components/header";
@import "_scss/components/social";
@import "_scss/components/carousel";
@import "_scss/components/hero";
@import "_scss/components/gallery";
@import "_scss/components/audio-player";
@import "_scss/components/audio-controls";
@import "_scss/components/audio-playlist";
@import "_scss/components/audio-info";


//views public
@import "_scss/views/home";
@import "_scss/views/about";
@import "_scss/views/projects";
@import "_scss/views/sound";
@import "_scss/views/contact";
@import "_scss/views/login";

//views private
@import "_scss/views/uiux";