.about__content {
    @include containerLayout(
        grid,
        $areas: 'left content',
        $rows: max-content, 
        $columns: var(--size-media) minmax(auto, var(--size-content)),
        $gap: var(--size-root-half) var(--size-root-2x), 
        $padding: var(--size-root-full),
        $width: var(--size-max),
        $margin: 0 auto
    );
    align-items: start;
    justify-content: center;
    animation: fadeUp var(--duration);

    @include breakpoint(small) {
        grid-template: initial;
        gap: var(--size-root-full) 0;
        min-height: 100vh;
    }

    h2,
    h3 {
        &:focus-visible {
            color: var(--color-body);
            text-shadow: 0px 2px 10px var(--color-link);
        }
    }

    h2,
    h3,
    h5,
    p,
    details {
        grid-area: content;
        grid-row: auto;
        display: block;
        width: 100%;
        white-space: break-spaces;
    }

    &--media {
        grid-area: left;
        grid-row: 1;
        align-self: flex-start;
        min-width: var(--size-media);
        margin: 10vh 0;
        border-radius: var(--border-radius-full);
        box-shadow: 0px 10px 20px 5px var(--color-link);
        overflow: hidden;
    }
    
    .img {
        position: sticky;
        justify-self: center;
        height: var(--size-media);
        top: calc(var(--size-header) + #{var(--size-root-full)});
        background: url(#{$pathToImages}/profile.png) center no-repeat;
        background-size: contain;
        background-color: var(--color-background-profile);

        @include breakpoint(small) {
            position: initial;
        }
    }

    &--content {
        grid-area: content;
        display: flex;
        flex-wrap: wrap;
        gap: var(--size-root-half) 0;

        @include breakpoint(small) {
            grid-row: 2;
        }
    }
}