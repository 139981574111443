.login {
    @include containerLayout(
        grid,
        $rows: auto, 
        $columns: 1fr,
        $gap: var(--size-root-full), 
        $padding: var(--size-root-full),
        $width: 100%,
        $margin: 0 auto
    );
    justify-items: center;
    align-items: center;
    min-height: calc(100vh - var(--size-header));

    form {
        @include containerLayout(
            grid,
            $rows: auto, 
            $columns: 1fr,
            $gap: var(--size-root-full), 
            $padding: var(--size-root-full),
            $width: auto,
            $margin: 0 auto
        );
        border-radius: var(--border-radius);
        animation: fadeUp var(--duration);

        @include breakpoint(small) {
            width: 90vw;
        }

        .element {
            @include containerLayout(
                grid,
                $rows: 1fr, 
                $columns: 1fr,
                $gap: var(--size-root-quarter), 
                $padding: initial,
                $width: initial,
                $margin: 0
            );
        }

        button {
            margin: 0 0 0 auto;
        }
    }

    & .response {
        text-align: center;
    }

    label {
        color: var(--color-label);
    }

    input[type='text'],
    input[type='password'] {
        color: var(--color-input-fg);
        font-family: var(--font-family-input);
        font-weight: var(--font-weight-input);
        background-color: var(--color-input-bg);
        padding: var(--size-root-full);
        border-radius: var(--border-radius);
        border: 1px solid var(--color-input-border);

        &:focus::placeholder {
            color: var(--color-placeholder);
            transition: var(--duration);
        }
    }
}
