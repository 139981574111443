.projects {
    
    .search {
        z-index: var(--depth-header);
        position: sticky;
        top: var(--size-header);
        width: 100%;
        max-width: var(--size-max);
        display: flex;
        justify-content: flex-end;
        margin: 0 auto;
        padding: var(--size-root-full);
        background-color: var(--color-body);

        & > label {
            display: none;
        }

        input[type="search"] {
            flex: 1 0 auto;
            font-size: var(--size-font);
            font-family: var(--font-family-input);
            font-weight: var(--font-weight-input);
            padding: var(--size-root-full);
            background-color: var(--color-input-bg);
            border: var(--focus-border-hidden);
            border-radius: var(--border-radius);
            transition: all var(--duration-quick);

            &::placeholder {
                color: var(--color-placeholder);
            }

            &:focus {
                color: var(--color-input-fg);
                outline: 2px solid var(--color-focus);

                & + i {
                    opacity: 0;
                }
            }
        }

        i {
            opacity: 1;
            position: absolute;
            align-self: center;
            right: var(--size-icon-large);
            width: var(--size-icon-large);
            height: var(--size-icon-large);
            pointer-events: none;
            transition: var(--duration-quick);

            &:before {
                content: "\f002"; //"\f0b0";
                display: flex;
                align-items: center;
                justify-content: center;
                width: var(--size-icon-large);
                height: var(--size-icon-large);
                color: var(--color-link);
                font-family: "Font Awesome 5 Free";
                font-size: var(--size-root-full);
            }
        }
    }

    &__controls {
        z-index: 2;
        grid-column: 1/-1;
        position: sticky;

        @include containerLayout(
            grid,
            $rows: auto,
            $columns: repeat(6, 1fr),
            $gap: var(--size-root-full),
            $padding: var(--size-root-full),
            $width: 100%,
            $margin: 0 auto
        );

        max-width: var(--size-detail);
        bottom: 0; // forces sticky
        background: var(--color-body);
        backdrop-filter: var(--backdrop-filter);
        transition: all var(--duration);

        & :last-child {
            grid-column: 6;
        }

        .btn__back,
        .btn__next,
        .btn__list {
            border: var(--focus-border-hidden);

            &:focus-visible {
                color: var(--color-link-h);
                border: var(--focus-border-active);
            }

            &:hover,
            &:focus:not(:focus-visible) {
                color: var(--color-link-h);
            }
        }
    }

    &--grid {
        @include containerLayout(
            grid,
            $rows: auto,
            $columns: repeat(3, minmax(200px, 1fr)),
            $gap: var(--size-root-full),
            $padding: var(--size-root-full),
            $width: var(--size-max),
            $margin: 0 auto
        );
        animation: fadeUp var(--duration);

        @include breakpoint(small) {
            grid-template: auto / repeat(2, 1fr);
        }
    }

    &__detail {
        @include containerLayout(
            grid,
            $rows: auto,
            $columns: 1fr,
            $gap: var(--size-root-half) var(--size-root-2x),
            $padding: var(--size-root-full),
            $width: var(--size-detail),
            $margin: 0 auto
        );
        animation: fadeUp var(--duration);

        &--media {
            aspect-ratio: 16/9;
            min-width: 100%;
            min-height: 300px;
            margin-bottom: var(--size-root-full);
            border-radius: var(--border-radius);
            background-color: var(--color-body-secondary);
            background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
            overflow: hidden;
            animation: fadeIn var(--duration-slow);

            @include breakpoint(small) {
                min-height: 180px;
                max-height: 30vh;
            }

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        &--video {
            display: flex;
            min-width: var(--size-media);
            margin: 0 0 var(--size-root-full) 0;
            border-radius: var(--size-root-half);
            background-color: var(--color-body-secondary);
            background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
            @include aspectRatioBox(3, 2);
            animation: fadeIn 1s;
            cursor: pointer;

            @include breakpoint(small) {
                grid-row: 1 / span 1;
            }

            img {
                position: absolute;
                width: 100%;
                height: auto;
                top: 0;
                margin: 0 auto 0 auto;
            }

            video {
                object-fit: contain;
            }
        }

        p:not(:first-of-type) {
            font-size: smaller;
        }

        p:first-of-type {
            padding-bottom: var(--size-root-half);
            white-space: pre-line;
        }

        p:last-of-type {
            padding-bottom: var(--size-root-half);
        }

        & .btn__link {
            justify-self: start;
            padding: 0 var(--size-root-half);
        }
    }
}

.web,
.design,
.video {
    &__thumb {
        aspect-ratio: 16/9;
        position: relative;
        width: 100%;
        background-color: var(--color-body-secondary);
        background: url(#{$pathToBrand}/icon-brand.png) center no-repeat;
        border: 4px solid transparent;
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;
        animation: fadeIn 1s;

        img {
            width: 100%;
            height: auto;
            margin: 0 auto;
            transition: all var(--duration-quick);
        }

        figcaption {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: var(--color-link);
            padding: var(--size-root-half);
            background-color: var(--color-gallery-overlay);
            backdrop-filter: var(--backdrop-filter);
            white-space: pre-line;
            transition: all var(--duration-quick);

            @include breakpoint(small) {
                font-size: x-small;
            }
        }

        &:hover,
        &:focus {
            img {
                filter: grayscale(1);
            }

            figcaption {
                opacity: 1;
            }
        }

        // default hover management
        &:hover,
        &:focus {
            border: 4px solid var(--color-link-h);
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);

            figcaption {
                color: var(--color-link-h);
            }
        }
    }
}

.screens {
    @include containerLayout(
        grid,
        $rows: auto,
        $columns: repeat(3, 1fr),
        $gap: var(--size-root-full),
        $padding: var(--size-root-full),
        $width: var(--size-detail),
        $margin: 0 auto
    );
    align-items: start;
    justify-items: center;
    background: var(--color-body-secondary);
    border-radius: var(--border-radius);

    @include breakpoint(small) {
        grid-template: auto / repeat(2, 1fr);
    }

    img {
        aspect-ratio: 16/9;
        width: 100%;
        object-fit: cover;
        object-position: top;
        border: 4px solid transparent;
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;

        &:hover,
        &:focus {
            border: 4px solid var(--color-link-h);
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);
        }
    }

    picture {
        aspect-ratio: 16/9;
        width: auto;
        display: grid;
        align-content: center;
        background-color: var(--color-body-secondary);
        border-radius: var(--border-radius);
        overflow: hidden;
        cursor: pointer;

        &:hover,
        &:focus {
            box-shadow: var(--box-shadow);
            transition: all var(--duration-quick);
        }

        img {
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}
