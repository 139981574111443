.nav {
    opacity: 0;
    grid-column: 1;
    grid-row: 2;
    z-index: -1;
    height: calc(100vh - var(--size-header));
    min-height: -webkit-fill-available; // mobile viewport bug fix
    line-height: calc(var(--line-height) * 2);
    background: var(--color-nav);
    backdrop-filter: var(--backdrop-filter);
    overscroll-behavior: contain;
    overflow-y: auto;
    scroll-behavior: smooth;
    pointer-events: none; // ensure's correct nav is clickable when active
    transition: all var(--duration-quick) ease-in;

    @include breakpoint(small) {
        width: 100vw;
    }

    &--visible {
        opacity: 1;
        pointer-events: auto; // initiate pointer events when visible
    }

    &__menu,
    &__settings {
        display: grid;
        align-content: center;
        justify-content: center;

        &--container {
            display: grid;
            align-content: center;
            justify-items: center;
        }
    }

    &__menu {
        --menu-spacing: 10vh;

        grid-column: 1;
        animation: fadeLeft var(--duration);

        @include breakpoint(small) {
            grid-column: 1 / -1;
            padding-top: var(--menu-spacing);
            padding-bottom: var(--menu-spacing);
        }

        &--container {
            margin-bottom: var(--size-root-2x); // adds distance between nav & social
        }
    }

    &__settings {
        grid-column: 3;
        animation: fadeRight var(--duration);

        @include breakpoint(small) {
            grid-column: 1 / -1;
        }

        &--container {
            @include breakpoint(small) {
                & ul:first-child {
                    margin-top: 10vh;
                }
                & span:last-child {
                    margin-bottom: 10vh;
                }
            }
        }
    }

    & ul > li {
        display: inline-block;
        width: 100%;

        & > a,
        .mode {
            display: flex;
            justify-content: center;
            width: auto;
            padding: var(--size-root-half);
            border: var(--focus-border-hidden);

            &.btn--active,
            &:hover,
            &:focus:not(:focus-visible) {
                color: var(--color-link-h);
            }

            &:focus-visible {
                color: var(--color-link-h);
                border: var(--focus-border-active);
            }
        }
    }

    .mode {
        gap: var(--size-root-half);
        color: var(--color-link);
        font-size: var(--size-font);
        background-color: transparent;
        cursor: pointer;

        &__light > i {
            transform: rotate(90deg);
        }

        &__dark > i {
            transform: rotate(270deg);
        }

        i {
            font-family: var(--font-awesome);
            font-weight: 400;
            transition: transform var(--duration);

            &:before {
                content: "\f042";
                width: var(--size-icon-medium);
                height: var(--size-icon-medium);
                font-size: var(--size-icon-medium);
            }
        }
    }

    .copyright {
        display: block;
        align-self: center;
        justify-self: center;
        font-size: var(--size-font-small);
        color: var(--color-content);
    }
}
