
.mode__light {

    // main theme light
    --color-header: rgba(245, 245, 245, 0.9);
    --color-body: rgba(245, 245, 245, 1);
    --color-body-secondary: rgba(235, 235, 235, 1);
    --color-footer: rgba(80, 80, 80, 1);
    --color-nav: rgba(245, 245, 245, 0.95);
    // --color-nav: linear-gradient(to bottom, rgba(230, 230, 230, 1) 0%, rgba(245, 245, 245, 1) 25%);

    --color-heading: rgba(80, 80, 80, 1);
    --color-heading-sub: rgba(120, 120, 120, 1);
    --color-content: rgba(80, 80, 80, 1);
    --color-content-highlight: rgba(180, 180, 180, 1);
    --color-content-light: rgba(245, 245, 245, 1);
    --color-footer-fg: rgba(245, 245, 245, 1);

    --color-link: rgba(0, 170, 255, 1);
    --color-link-h: rgba(10, 130, 190, 1);

    --color-loading: rgba(180, 180, 180, 1);

    // scrollbars
    --color-track: rgba(245, 245, 245, 1);
    --color-thumb: rgba(0, 170, 255, 1);

    // custom content
    --color-hero-content: var(--color-heading);
    --color-hero-background: var(--color-body);
    
    --color-hero-page-background: linear-gradient(to top, var(--color-body), rgba(10, 130, 220, 0.25));
    --size-hero-page-divider: rgba(0, 170, 255, 1);

    --color-background-profile: rgba(0, 0, 0, 1);
    --color-meter: rgba(80, 80, 80, 1);
    --color-meter-label: rgba(245, 245, 245, 1);
    --color-meter-bar-fg: rgba(0, 170, 255, 1);
    --color-meter-bar-bg: rgba(10, 130, 190, 1);

    --color-media-overlay: linear-gradient(0deg, rgba(245, 245, 245, 1) 100%, transparent);

    --color-galery-background: rgb(255, 255, 255);
    --color-gallery-overlay: rgba(0, 0, 0, 0.75);

    // audio player
    --color-background-player: var(--color-body);
    --color-background-player-list: rgba(0, 0, 0, 0.1);
}